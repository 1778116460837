import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import MyFacebookID from './MyFacebookID';

import { Router, Route, Switch } from "react-router";
import { createBrowserHistory } from "history";
import * as serviceWorker from './serviceWorker';

const history = createBrowserHistory();


ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <Switch>
        <Route exact path="/privacy_policy" render={ () =>(
          <PrivacyPolicy />
        )}/>
        <Route exact path="/terms_of_service" render={ () =>(
          <TermsOfService />
        )}/>
        <Route exact path="/my_facebook_id" render={ () =>(
          <MyFacebookID />
        )}/>
        <Route exact path="/" render={ () =>(
          <App />
        )}/>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')

);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
