import React, {Component} from 'react';
import axios from 'axios';
import logo from './resources/logo.svg';
import './App.css';
//import { FacebookProvider, LoginButton } from 'react-facebook';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      facebookAppId: 289310208577743,
      //facebookAppId: 363822171661126,
      apiloaded: false,
      permissions: "public_profile,email,user_link,user_likes,user_posts,user_photos,user_friends,user_location,pages_read_engagement,pages_manage_posts",
      permissions: "",
      user: {
          id: "",
          name: "",
          status: ""
      },
      backendapi: "https://fortebet-backend.mtmsdev.com/api/facebook_page",
      //backendapi: "http://127.0.0.1:8000/api/facebook_page",
      endpoints: {
        tenis: "https://fortebet-backend.mtmsdev.com/api/contest_tenis",
        football: "https://fortebet-backend.mtmsdev.com/api/contest_football"
      },
      /*
      endpoints: {
        tenis: "http://127.0.0.1:8000//api/contest_tenis",
        football: "http://127.0.0.1:8000//api/contest_football"
      },
      */
      backenddata: {
        no_contest: false,
        description: "",
        end: "",
        image: "",
        show_form: "",
        start: "",
        time: "",
        title: "",
        today: "",
        type: ""
      },
      formdata_football: {
        home: "",
        away: "",
        names_of_players: "",
        username: "",
        mobile: "",
        ticketnumber: ""
      },
      formdata_football_response: {
        predictionnumber: "",
        username: "",
        ticketnumber: "",
        mobile: "",
        createdat: "",
        prediction: {
          home: "",
          away: "",
          names_of_players: ""
        }
      },
      formdata_football_show_error: false,
      formdata_football_error: {
        home: "",
        away: "",
        names_of_players: "",
        username: "",
        mobile: "",
        ticketnumber: ""
      },
      // formdata_tenis: {
      //   gamenumber: "",
      //   gamenumber2: "",
      //   username: "",
      //   mobile: "",
      //   ticketnumber: ""
      // },
      // formdata_tenis_response: {
      //   predictionnumber: "",
      //   username: "",
      //   ticketnumber: "",
      //   mobile: "",
      //   createdat: "",
      //   prediction: {
      //     gamenumber: "",
      //     gamenumber2: "",
      //   }
      // },
      // formdata_tenis_show_error: false,
      // formdata_tenis_error: {
      //   gamenumber: "",
      //   gamenumber2: "",
      //   username: "",
      //   mobile: "",
      //   ticketnumber: ""
      // },
      formdata_tenis: {
        totalgames: "",
        winner: "",
        username: "",
        mobile: "",
        ticketnumber: ""
      },
      formdata_tenis_response: {
        predictionnumber: "",
        username: "",
        ticketnumber: "",
        mobile: "",
        createdat: "",
        prediction: {
          totalgames: "",
          winner: "",
        }
      },
      formdata_tenis_show_error: false,
      formdata_tenis_error: {
        totalgames: "",
        winner: "",
        username: "",
        mobile: "",
        ticketnumber: ""
      },
      officialweb: "https://www.fortebet.ug/",
      logo: {
        src: logo,
        alt: "Fortebet logo"
      },

    }

    this.handleFBLogin = this.handleFBLogin.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.parseErrorMessages = this.parseErrorMessages.bind(this);
  }
  componentDidMount() {

    let self = this;
    this.loadFBApi(self);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.apiloaded === true && prevState.apiloaded !== this.state.apiloaded) {
      let status = null;
      let id = null;
      let name = null;

      let self = this;
      window.FB.getLoginStatus(function(response) {

        //console.log(response);
        status = response.status;

        if (status === "connected") {
          window.FB.api(
            '/me',
            function(response) {
             id = response.id;
             name = response.name;
             self.setState(() => ({
               user:{
                 status: status,
                 id: id,
                 name: name
               }
             }), () => {
               //console.log("axios content from server api");
               self.loadAxiosBackendData();

             });
          });

          /*
          window.FB.api(
            '/me/permissions',
            function(response) {
              //console.log(response);
              self.checkGrantedPermissions(response);
            }
          );
          //check permissions from granted from user
          */
        }
      });
      /*window.FB.login(function(response) {
        console.log(response);
      });
      */
    }

  }
  checkGrantedPermissions(permissions) {
    console.log(permissions);
    let self = this;
    window.FB.login(function(response) {
      // handle the response
      console.log(response);
      //id,address,age_range,birthday,email,gender,hometown,installed,languages,link,location,name,profile_pic
      window.FB.api(
        '/me',
        'GET',
        {"fields": "id,name,age_range,birthday,email,gender,link,location"},
        function(response) {
          console.log(response);
      });
      /*
      window.FB.api(
        '/me/feed',
        'GET',
        {},
        function(response) {
          console.log(response);
      });

      window.FB.api(
        '/'+self.state.user.id+'/picture?redirect=false',
        'GET',
        {},
        function(response) {
          console.log(response);
      });

      window.FB.api(
        '/me/photos',
        'GET',
        {},
        function(response) {
          console.log(response);
      });

      window.FB.api(
        '/me/likes',
        'GET',
        {},
        function(response) {
          console.log(response);
      });

      window.FB.api(
        '/me/friends',
        'GET',
        {"fields": "uid"},
        function(response) {
          console.log(response);
      });
      */
    }, {
      scope: this.state.permissions,
      return_scopes: true
    });




  }
  loadAxiosBackendData() {
    let payload = {
      "uid" : this.state.user.id
    };
    axios.post(
      this.state.backendapi,
      payload
    ).then(response => {

      if (response.data === "no_contest") {
        this.setState(() => ({
          backenddata: {
            ...this.state.backenddata,
            no_contest: true
          }

        }));
      } else {
        //console.log(response.data);
          if (response.data.show_form === "true") {
            this.setState(() => ({
              backenddata: {
                no_contest: false,
                description: response.data.description,
                end: response.data.end,
                image: response.data.image,
                show_form: response.data.show_form,
                start: response.data.start,
                time: response.data.time,
                title: response.data.title,
                today: response.data.today,
                type: response.data.type
              }
            }));
          } else {

            if (response.data.type === "football") {

              this.setState(() => ({
                backenddata: {
                  no_contest: false,
                  description: response.data.description,
                  end: response.data.end,
                  image: response.data.image,
                  show_form: response.data.show_form,
                  start: response.data.start,
                  time: response.data.time,
                  title: response.data.title,
                  today: response.data.today,
                  type: response.data.type
                },
                formdata_football_response: {
                  predictionnumber: response.data.number_of_prediciton,
                  username: response.data.username,
                  ticketnumber: response.data.ticket_number,
                  mobile: response.data.registered_number,
                  createdat: response.data.created_at,
                  prediction: {
                    home: response.data.prediction.home,
                    away: response.data.prediction.away,
                    names_of_players: response.data.prediction.player_name,
                  }
                },
              }));
            }

            if (response.data.type === "tenis") {
              this.setState(() => ({
                backenddata: {
                  no_contest: false,
                  description: response.data.description,
                  end: response.data.end,
                  image: response.data.image,
                  show_form: response.data.show_form,
                  start: response.data.start,
                  time: response.data.time,
                  title: response.data.title,
                  today: response.data.today,
                  type: response.data.type
                },
                formdata_tenis_response: {
                  predictionnumber: response.data.number_of_prediciton,
                  username: response.data.username,
                  ticketnumber: response.data.ticket_number,
                  mobile: response.data.registered_number,
                  createdat: response.data.created_at,
                  prediction: {
                    totalgames: response.data.prediction.total_games,
                    winner: response.data.prediction.winner
                    // gamenumber: response.data.prediction.number_of_games,
                    // gamenumber2: response.data.prediction.number_of_games2,
                  }
                },
              }));
            }



          }


      }

    }).catch(error => {
      console.log(error);
    });
  }

  loadFBApi(self) {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId            : self.state.facebookAppId,
        cookie           : true,
        version          : 'v8.0'
      });
      self.setState({
        apiloaded: true
      });

    };

    //console.log("loading fb api");
    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id))
        return;

      js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));


  }
  handleFBLogin() {
    if (!this.state.apiloaded) {
      return
    }
    let status = null;
    let id = null;
    let name = null;

    let self = this;

    window.FB.login(function(response) {

      status = response.status;
      if (response.authResponse) {
       window.FB.api('/me', function(response) {

          id = response.id;
          name = response.name;
          self.setState(() => ({
            user:{
              status: status,
              id: id,
              name: name
            }
          }), () => {
            console.log("axios content from server api");
            self.loadAxiosBackendData();
          });
       });
      } else {
       //console.log('User cancelled login or did not fully authorize.');
      }
    }, {
      scope: this.state.permissions,
      return_scopes: true
    });


  }
  handleOnChange(event, type) {

    const target = event.target;
    const value = target.value;
    const name = target.name;
    console.log(name);
    if (type === "football") {
      this.setState(() => ({
        formdata_football: {
          ...this.state.formdata_football,
          [name]: value
        }
      }));
    }

    if (type === "tenis") {
      this.setState(() => ({
        formdata_tenis: {
          ...this.state.formdata_tenis,
          [name]: value
        }
      }));
    }



  }

  showResponseMessages(messages, type) {
    //console.log(messages);
    if (type === "football") {
      this.setState(() => ({
        backenddata: {
          ...this.state.backenddata,
          show_form: "false",
        },
        formdata_football_response: {
          predictionnumber: messages.number_of_prediciton,
          username: messages.username,
          ticketnumber: messages.ticket_number,
          mobile: messages.registered_number,
          createdat: messages.created_at,
          prediction: {
            home: messages.prediction.home,
            away: messages.prediction.away,
            names_of_players: messages.prediction.player_name,
          }
        },
      }));
    }
    if (type === "tenis") {
      //console.log(messages);
      this.setState(() => ({
        backenddata: {
          ...this.state.backenddata,
          show_form: "false",
        },
        formdata_tenis_response: {
          predictionnumber: messages.number_of_prediciton,
          username: messages.username,
          ticketnumber: messages.ticket_number,
          mobile: messages.registered_number,
          createdat: messages.created_at,
          prediction: {
            totalgames: messages.prediction.total_games,
            winner: messages.prediction.winner
            // gamenumber: messages.prediction.number_of_games,
            // gamenumber2: messages.prediction.number_of_games2,
            
          }
        },
      }));
    }


  }
  parseErrorMessages(messages, type) {
    //console.log(messages);
    if (type === "football") {
      this.setState(() => ({
        formdata_football_show_error: true,
        formdata_football_error: {
          home: messages.home,
          away: messages.away,
          names_of_players: messages.playerName,
          username: messages.fortebetID_football,
          mobile: messages.phone_number_football,
          ticketnumber: messages.ticket_number_football
        }
      }));
    }

    if (type === "tenis") {
      this.setState(() => ({
        formdata_tenis_show_error: true,
        formdata_tenis_error: {
          totalgames: messages.total_games,
          winner: messages.winner,
          // gamenumber: messages.gamenumber,
          // gamenumber2: messages.gamenumber2,

          username: messages.fortebetID_tenis,
          mobile: messages.phone_number_tenis,
          ticketnumber: messages.ticket_number_tenis
        }
      }));
    }

  }
  createFormData(type) {

    let payload = {};

    if (type === "football") {
      payload = {
        home: this.state.formdata_football.home,
        away: this.state.formdata_football.away,
        playerName: this.state.formdata_football.names_of_players,
        fortebetID_football: this.state.formdata_football.username,
        phone_number_football: this.state.formdata_football.mobile,
        facebookID: this.state.user.id,
        name: this.state.user.name,
        ticket_number_football: this.state.formdata_football.ticketnumber
      };

    }

    if (type === "tenis") {
      payload = {
        total_games: this.state.formdata_tenis.totalgames,
        winner: this.state.formdata_tenis.winner,
        // firstGameNumber: this.state.formdata_tenis.gamenumber,
        // secondGameNumber: this.state.formdata_tenis.gamenumber2,
        fortebetID_tenis: this.state.formdata_tenis.username,
        phone_number_tenis: this.state.formdata_tenis.mobile,
        facebookID: this.state.user.id,
        name: this.state.user.name,
        ticket_number_tenis: this.state.formdata_tenis.ticketnumber,
      };
    }

    return payload;

  }
  handleSubmit(event, type) {

    event.preventDefault();


    if (type === "football") {

      let formData = this.createFormData(type);

      console.log(formData);
      axios.post(
        this.state.endpoints.football,
        formData,
        {
           headers: {
               'Content-Type': 'application/json'
           }
         }
      ).then(response => {
        console.log(response);
        this.showResponseMessages(response.data, type);
      }).catch((error, state) => {

        if (error.response && error.response.data != null) {
          //console.log(error.response.data);
          this.parseErrorMessages(error.response.data, type);
        }

      });
    }

    if (type === "tenis") {

      let formData = this.createFormData(type);

      console.log(formData);
      axios.post(
        this.state.endpoints.tenis,
        formData,
        {
           headers: {
               'Content-Type': 'application/json'
           }
         }
      ).then(response => {
        console.log(response);
        this.showResponseMessages(response.data, type);
      }).catch(error => {
        //console.log(error);
        if (error.response.status && error.response.data != null) {
          //console.log(error.response.data);
          this.parseErrorMessages(error.response.data, type);
        }
      });
    }

  }

  renderHeader() {
    if (this.state.user.status === "connected" && !this.state.backenddata.no_contest && this.state.backenddata.image !== "") {
      return (

        <div className="banner">
          <img src={this.state.backenddata.image} alt="event banner"/>
        </div>
      )
    }
  }
  renderDescription() {
    return (
      <div className="description">
        <div className="title">
          <h1>{this.state.backenddata.title}</h1>
        </div>
        <div>
        <span>{this.state.backenddata.description}</span>
        </div>
      </div>
    )
  }

  renderCreatedAt(createdat) {

    //console.log(createdat);
    //console.log(typeof createdat);
    //return;

    let datetime = createdat.split(" ");

    let date = datetime[0].split("-");
    let time = datetime[1].split(":");

    return (
        <span>
          <span>{date[2]}.{date[1]}.{date[0]} </span>
          <span>{time[0]}:{date[1]} </span>
        </span>

    );
  }


  renderForm() {

    if (this.state.backenddata.show_form === "false") {
      if (this.state.backenddata.type === "football") {
        return (
          <div className="user_prediction_wrapper football form_wrapper">
            <div className="form">
              <div className="row_wrapper">
                <div><span>Your prediction was saved.</span></div>
              </div>
              <div className="row_wrapper">
                <div>
                  <span>
                    PREDICTION:
                  </span>

                  <span>
                    {this.state.formdata_football_response.predictionnumber}
                  </span>
                </div>
              </div>
              <div className="row_wrapper">
                <div>
                  <span>
                    Home:
                  </span>

                  <span>
                    {this.state.formdata_football_response.prediction.home}
                  </span>
                </div>
              </div>
              <div className="row_wrapper">
                <div>
                  <span>
                    Away:
                  </span>

                  <span>
                    {this.state.formdata_football_response.prediction.away}
                  </span>
                </div>
              </div>
              <div className="row_wrapper">
                <div>
                  <span>
                    Goalscorer's Name:
                  </span>

                  <span>
                    {this.state.formdata_football_response.prediction.names_of_players}
                  </span>
                </div>
              </div>
              <div className="row_wrapper">
                <div>
                  <span>
                    USERNAME:
                  </span>

                  <span>
                    {this.state.formdata_football_response.username}
                  </span>
                </div>
              </div>
              <div className="row_wrapper">
                <div>
                  <span>
                    TICKET NUMBER:
                  </span>

                  <span>
                    {this.state.formdata_football_response.ticketnumber}
                  </span>
                </div>
              </div>
              <div className="row_wrapper">
                <div>
                  <span>
                    REGISTERED NUMBER:
                  </span>

                  <span>
                    {this.state.formdata_football_response.mobile}
                  </span>
                </div>
              </div>
              <div className="row_wrapper">
                <div>
                  <span>
                    SAVED AT:
                  </span>

                  <span>
                    {this.renderCreatedAt(this.state.formdata_football_response.createdat)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      }

      if (this.state.backenddata.type === "tenis") {
        return (
          <div className="user_prediction_wrapper tenis form_wrapper">
            <div className="form">
              <div className="row_wrapper">
                <div><span>Your prediction was saved.</span></div>
              </div>
              <div className="row_wrapper">
                <div>
                  <span>
                    PREDICTION:
                  </span>

                  <span>
                    {this.state.formdata_tenis_response.predictionnumber}
                  </span>
                </div>
              </div>
              <div className="row_wrapper">
                <div>
                  <span>EXACT AMOUNT OF GAMES:</span>
                  <span>
                    {this.state.formdata_tenis_response.prediction.totalgames}
                  </span>
                </div>                
              </div>
              <div className="row_wrapper">
                <div>
                  <span>MATCH WINNER:</span>
                  <span>
                    {this.state.formdata_tenis_response.prediction.winner}
                  </span>
                </div>                
              </div>
              <div className="row_wrapper">
                <div>
                  <span>USERNAME:</span>
                  <span>
                    {this.state.formdata_tenis_response.username}
                  </span>
                </div>                
              </div>
              <div className="row_wrapper">
                <div>
                  <span>TICKET NUMBER:</span>
                  <span>
                    {this.state.formdata_tenis_response.ticketnumber}
                  </span>
                </div>                
              </div>
              <div className="row_wrapper">
                <div>
                  <span>REGISTERED NUMBER:</span>
                  <span>
                    {this.state.formdata_tenis_response.mobile}
                  </span>
                </div>                
              </div>
              <div className="row_wrapper">
                <div>
                  <span>SAVED AT:</span>
                  <span>
                    {this.renderCreatedAt(this.state.formdata_tenis_response.createdat)}
                  </span>
                </div>                
              </div>
            </div>
          </div>
        );
      }

    }

    if (this.state.backenddata.type === "football") {
      return (
        <div className="form_wrapper">
          <form className="form" onSubmit={(e) => this.handleSubmit(e, "football")}>

            <div className="row_wrapper">
              <div className="label_wrapper">
                <label>Your prediction:</label>
              </div>
              <div className="grid_wrapper">
                <div className="grid_item_input">
                  <div className="input_wrapper">
                    <input
                      type="number"
                      value={this.state.formdata_football.home}
                      name="home"
                      placeholder="Home"
                      onChange={(e) => this.handleOnChange(e, this.state.backenddata.type)}/>
                    <div className={"error_message_wrapper" + ((this.state.formdata_football_show_error) ? " show" : "") }>
                      <span>{this.state.formdata_football_error.home}</span>
                    </div>
                  </div>
                </div>

                <div className="grid_item_middle">
                  <div className="middle_point">X</div>
                </div>

                <div className="grid_item_input">
                  <div className="input_wrapper">
                    <input
                      type="number"
                      value={this.state.formdata_football.away}
                      name="away"
                      placeholder="Away"
                      onChange={(e) => this.handleOnChange(e, this.state.backenddata.type)}/>
                    <div className={"error_message_wrapper" + ((this.state.formdata_football_show_error) ? " show" : "") }>
                      <span>{this.state.formdata_football_error.away}</span>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div className="row_wrapper">
              <div className="label_wrapper">
                <label>Goalscorer's Name:</label>
              </div>
              <div className="input_wrapper">
                <input
                  type="text"
                  value={this.state.formdata_football.names_of_players}
                  name="names_of_players"
                  placeholder=""
                  onChange={(e) => this.handleOnChange(e, this.state.backenddata.type)}/>
                <div className={"error_message_wrapper" + ((this.state.formdata_football_show_error) ? " show" : "") }>
                  <span>{this.state.formdata_football_error.names_of_players}</span>
                </div>
              </div>
            </div>

            <div className="row_wrapper">
              <div className="label_wrapper">
                <label>Ticket number:</label>
              </div>
              <div className="input_wrapper">
                <input
                  type="text"
                  value={this.state.formdata_football.ticketnumber}
                  name="ticketnumber"
                  placeholder=""
                  onChange={(e) => this.handleOnChange(e, this.state.backenddata.type)}/>
                <div className={"error_message_wrapper" + ((this.state.formdata_football_show_error) ? " show" : "") }>
                  <span>{this.state.formdata_football_error.ticketnumber}</span>
                </div>
              </div>
            </div>

            <div className="row_wrapper">
              <div className="label_wrapper">
                <label>Your ForteBet registered username:</label>
              </div>
              <div className="input_wrapper">
                <input
                  type="text"
                  value={this.state.formdata_football.username}
                  name="username"
                  placeholder=""
                  onChange={(e) => this.handleOnChange(e, this.state.backenddata.type)}/>
                <div className={"error_message_wrapper" + ((this.state.formdata_football_show_error) ? " show" : "") }>
                  <span>{this.state.formdata_football_error.username}</span>
                </div>
              </div>
            </div>

            <div className="row_wrapper">
              <div className="label_wrapper">
                <label>YOUR FORTEBET REGISTERED MOBILE NUMBER:</label>
              </div>
              <div className="number_with_prefix">
                  <div className="input_wrapper prefix">
                    <input
                      type="text"
                      value="+256"
                      name="prefix"
                      placeholder=""
                      disabled/>
                  </div>


                  <div className="input_wrapper number">
                    <input
                      type="number"
                      value={this.state.formdata_football.mobile}
                      name="mobile"
                      placeholder="123456789"
                      onChange={(e) => this.handleOnChange(e, this.state.backenddata.type)}/>
                    <div className={"error_message_wrapper" + ((this.state.formdata_football_show_error) ? " show" : "") }>
                      <span>{this.state.formdata_football_error.mobile}</span>
                    </div>
                  </div>

              </div>

            </div>

            <div className="row_wrapper">
              <div className="input_wrapper">
                <input
                  className="submit"
                  type="submit"
                  value="Submit your prediction"
                />
              </div>
            </div>

          </form>
        </div>
      )

    }

    if (this.state.backenddata.type === "tenis") {
      return (
        <div className="form_wrapper">
          <form className="form" onSubmit={(e) => this.handleSubmit(e, "tenis")}>

          {/* <div className="row_wrapper">
            <div class="label_wrapper">
              <label>Your prediction:</label>
              <span class="sub_label">(exact sum all of games in all sets)</span>
            </div>
            <div className="grid_wrapper">
              <div className="grid_item_input">
                <div className="input_wrapper">
                  <input
                    type="number"
                    value={this.state.formdata_tenis.gamenumber}
                    name="gamenumber"
                    placeholder="Number of game"
                    onChange={(e) => this.handleOnChange(e, this.state.backenddata.type)}/>
                  <div className={"error_message_wrapper" + ((this.state.formdata_tenis_show_error) ? " show" : "") }>
                    <span>{this.state.formdata_tenis_error.gamenumber}</span>
                  </div>
                </div>
              </div>

              <div className="grid_item_middle">
                <div className="middle_point">:</div>
              </div>

              <div className="grid_item_input">
                <div className="input_wrapper">
                  <input
                    type="number"
                    value={this.state.formdata_tenis.gamenumber2}
                    name="gamenumber2"
                    placeholder="Number of game"
                    onChange={(e) => this.handleOnChange(e, this.state.backenddata.type)}/>
                  <div className={"error_message_wrapper" + ((this.state.formdata_tenis_show_error) ? " show" : "") }>
                    <span>{this.state.formdata_tenis_error.gamenumber2}</span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="row_wrapper">
            <div class="label_wrapper">
              <label>Exact amount of games</label>
            </div>
            
              <div className="input_wrapper">
                  <input
                    type="number"
                    min='0'
                    value={this.state.formdata_tenis.totalgames}
                    name="totalgames"
                    placeholder="Number of games"
                    onChange={(e) => this.handleOnChange(e, this.state.backenddata.type)}/>
                  <div className={"error_message_wrapper" + ((this.state.formdata_tenis_show_error) ? " show" : "") }>
                    <span>{this.state.formdata_tenis_error.totalgames}</span>
                  </div>
                </div>
            
          </div>
          <div className="row_wrapper">
            <div class="label_wrapper">
              <label>Match winner</label>
            </div>
              <div className="input_wrapper">
                  <input
                    type="text"
                    value={this.state.formdata_tenis.winner}
                    name="winner"
                    placeholder="Winner"
                    onChange={(e) => this.handleOnChange(e, this.state.backenddata.type)}/>
                  <div className={"error_message_wrapper" + ((this.state.formdata_tenis_show_error) ? " show" : "") }>
                    <span>{this.state.formdata_tenis_error.winner}</span>
                  </div>
                </div>
            
          </div>
          <div className="row_wrapper">
            <div className="label_wrapper">
              <label>Ticket number:</label>
            </div>
            <div className="input_wrapper">
              <input
                type="text"
                value={this.state.formdata_tenis.ticketnumber}
                name="ticketnumber"
                placeholder=""
                onChange={(e) => this.handleOnChange(e, this.state.backenddata.type)}/>
              <div className={"error_message_wrapper" + ((this.state.formdata_tenis_show_error) ? " show" : "") }>
                <span>{this.state.formdata_tenis_error.ticketnumber}</span>
              </div>
            </div>
          </div>

          <div className="row_wrapper">
            <div className="label_wrapper">
              <label>Your ForteBet registered username:</label>
            </div>
            <div className="input_wrapper">
              <input
                type="text"
                value={this.state.formdata_tenis.username}
                name="username"
                placeholder=""
                onChange={(e) => this.handleOnChange(e, this.state.backenddata.type)}/>
              <div className={"error_message_wrapper" + ((this.state.formdata_tenis_show_error) ? " show" : "") }>
                <span>{this.state.formdata_tenis_error.username}</span>
              </div>
            </div>
          </div>

          <div className="row_wrapper">
            <div className="label_wrapper">
              <label>YOUR FORTEBET REGISTERED MOBILE NUMBER:</label>
            </div>
            <div className="number_with_prefix">
                <div className="input_wrapper prefix">
                  <input
                    type="text"
                    value="+256"
                    name="prefix"
                    placeholder=""
                    disabled/>
                </div>


                <div className="input_wrapper number">
                  <input
                    type="number"
                    value={this.state.formdata_tenis.mobile}
                    name="mobile"
                    placeholder="123456789"
                    onChange={(e) => this.handleOnChange(e, this.state.backenddata.type)}/>
                  <div className={"error_message_wrapper" + ((this.state.formdata_tenis_show_error) ? " show" : "") }>
                    <span>{this.state.formdata_tenis_error.mobile}</span>
                  </div>
                </div>

            </div>

          </div>

          <div className="row_wrapper">
            <div className="input_wrapper">
              <input
                className="submit"
                type="submit"
                value="Submit your prediction"
              />
            </div>
          </div>

          </form>
        </div>
      )
    }

  }
  renderContent() {

    if (this.state.user.status === "connected") {
      if (this.state.backenddata.no_contest) {
        return (
          <div>
            <span>
              There is no active competition now. Please follow our Facebook page to stay informed.
            </span>
          </div>
        )
      } else {
        return (
          <div className="event">
            {this.renderDescription()}
            {this.renderForm()}

          </div>
        )
      }


    } else {
      return (
        <div  className="button_wrapper">
          <div className="button" onClick={this.handleFBLogin}>
            <h1>Continue with Facebook</h1>
          </div>
        </div>
      )
    }
  }

  renderFooter() {
    if (this.state.user.status === "connected") {

    }
  }

  render() {
      return (
        <div className="facebook_app_wrapper">

          <div className="header">
            {this.renderHeader()}
          </div>
          <div className="content">
            {this.renderContent()}
          </div>
          <div className="footer">
            <div className="note">
              <span>
                * VIP points can be credited to registered Fortebet clients only. You don't have an online account yet? Register right now at <a href={this.state.officialweb}>www.fortebet.ug.</a>
              </span>
            </div>

            <div className="logo">
              <img src={this.state.logo.src} alt={this.state.logo.alt}/>
            </div>
          </div>
        </div>
      )
  }
}


export default App;
