import React from 'react';

export default function TermsOfService() {
  return(
      <div className="facebook_app_wrapper" style={{margin: "50px auto"}}>
      <h1>Rules of online competition held on the official ForteBet Facebook page</h1>
      <h2>1. THE ORGANISER OF THE COMPETITION</h2>
      The organizer, promoter and advertiser of the competition held on the official ForteBet Facebook page (hereinafter referred to as “the competition”) is the company Grand Victoria LTD. having its registered office at Plot 3 Dundas Road, Kololo Courts 1st Floor, P. O. Box 75640, Lower Kololo, Kampala Uganda (hereinafter referred to as “the organizer”).
      <h2>2. COMPETITION MECHANICS</h2>
      The competition is implemented in the form of a question that is asked in a contribution published by the company ForteBet on its official page on the Facebook social networking site (hereinafter referred to as “Facebook”). The duration of every competition is determined and specified by the organizer of the competition and is always mentioned in the competition instructions. To the competition includes only those answers that are put in the competition form.
      In case there are more correct answers than announced in the instructions, the time of submission of the answer shown below the appropriate answer is decisive. If there are more competitors with a time identical (to an accuracy of minutes) to the last winner’s time, all of these winners will receive the winnings.
      <h2>3. PARTICIPATION IN THE COMPETITION</h2>
      Under-age persons are not allowed to take part in the competition. Those excluded from the competition also include persons in an employment or similar relationship to the organizer of the competition or to other persons in charge of ensuring of organization of this competition as well as persons related to any of these persons.
      Any winning can be claimed with the company ForteBet per user name just from one Facebook profile. When a competition profile is changed, it is necessary to contact the organizer of the competition by means of a private message on Facebook before the end of the competition. Otherwise, the last profile to win for the given user name will be taken as the only valid profile. Claiming winnings from a profile other than this one will not be taken in account.
      Only users with player’s accounts registered with the company ForteBet are entitled to winnings.
      Only the first prediction will be considered. Any editing will not be considered even if done before the start of the game.
      <h2>4. EVALUATION AND WINNINGS</h2>
      Winnings are always determined by the organizer of the competition. The number of winners and the amount of winnings can vary in different competitions. Selection of the winners is determined by the organizer of the competition.
      <h2>5. RIGHTS AND OBLIGATIONS OF THE ORGANISER OF THE COMPETITION</h2>
      In case of any breach of these rules, a fraudulent claim to win, a breach of the rules of the Facebook social network or any conduct contrary to morality, the company reserves the right to terminate the competition, modify these terms and conditions, refuse to pay out possible wins and block the user’s competition accounts at any time without any notification or explanation.
      <h2>6. FINAL PROVISIONS AND CONDITIONS</h2>
      The company Facebook, Inc., is not responsible for this competition, its technical side, the course of the competition, the announcement of the winners and prize giving in any way. A participant in the competition agrees to present information to the organizer of the competition and not to the electronic social network Facebook. The organizer of the competition is not responsible for any technical problems in connection with participation in the event (especially reliability performance of the Internet and the social network Facebook).
      If the organizer of the competition is justifiably suspicious of any fraudulent, unfaithful dealing or unfair practices of a competitor or another person that has helped or could have helped a competitor achieve a win or if such a conduct occurs, the competitor shall be excluded from the competition; this also applies in the event of another conduct committed by a competitor or another person that has helped a competitor or could have helped achieve a win, which is found to be in breach of the rules or terms and conditions of the competition or contrary to the principles of fair competition and fair play.
      The organizer of the competition is not responsible for any damage caused by preparation or implementation of any competition acts, participation in the competition or winnings in the competition or in relation to them.
      The organizer of the competition is entitled to definitely exclude a competitor in the event of a suspicion that the competitor has achieved his or her result in the competition by virtue of a fraudulent conduct or conduct that is contrary to morality able to affect the outcome of this competition. This organizer’s decision is final, unappealable.
      The organizer of the competition is not responsible for any loss, non-delivery, delay or damage while delivering a possible material betting win.
      Taking part in the competition, the competitor expresses his or her consent with the rules of the competition and undertakes to fully adhere to these rules.
      It is not possible to enforce anybody’s participation in the competition by legal action.
      These rules become effective on 1st December 2018


      </div>
  );
}
