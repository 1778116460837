import React, {Component} from 'react';

class MyFacebookID extends Component  {
  constructor(props) {
    super(props);

    this.state = {
      facebookAppId: 289310208577743,
      apiloaded: false,
      user: {
          id: "",
          name: "",
          status: ""
      },
    }

    this.handleFBLogin = this.handleFBLogin.bind(this);
  }
  componentDidMount() {

    let self = this;
    this.loadFBApi(self);
  }

  loadFBApi(self) {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId            : self.state.facebookAppId,
        cookie           : true,
        version          : 'v8.0'
      });
      self.setState({
        apiloaded: true
      });

    };

    console.log("loading fb api");
    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id))
        return;

      js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));


  }
  handleFBLogin() {

    if (!this.state.apiloaded) {
      return
    }

    let status = null;
    let id = null;
    let name = null;

    let self = this;

    window.FB.login(function(response) {

      status = response.status;
      if (response.authResponse) {
       window.FB.api('/me', function(response) {

          id = response.id;
          name = response.name;
          self.setState(() => ({
            user:{
              status: status,
              id: id,
              name: name
            }
          }), () => {
            //console.log("axios content from server api");
            //self.loadAxiosBackendData();
          });
       });
      } else {
       //console.log('User cancelled login or did not fully authorize.');
      }
    }, {
      //scope: this.state.permissions,
      //return_scopes: true
    });


  }

  renderFacebookID() {
    return(
      <div className="my_facebook_id">
        <div className="row_wrapper">
          <div className="input_wrapper">
            <input
              type="text"
              value={this.state.user.id}
              name="user_id"
              disabled/>
          </div>
        </div>
      </div>
    )
  }
  renderContent() {
    return(
      <div  className="button_wrapper">
        <div className="button" onClick={this.handleFBLogin}>
          <h1>Show My Facebook ID</h1>
        </div>
        {this.renderFacebookID()}
      </div>
    );
  }

  render() {
    return (
      <div className="facebook_app_wrapper">
        <div className="content">
          {this.renderContent()}

        </div>
      </div>
    )
  }
}

export default MyFacebookID;
