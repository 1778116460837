import React from 'react';

export default function PrivacyPolicy() {
  return(
    <div className="facebook_app_wrapper" style={{margin: "50px auto"}}>
    <h1>Privacy Policy (Customers)</h1>

    This Privacy Policy describes the way in which ForteBet deal with the information and data you provide to us to enable us to manage your relationship with ForteBet.

    We will process any personal information provided to us or otherwise held by us relating to you in the manner set out in this Privacy Policy. Information may be provided via ForteBet branches, website (the "Website"), telephone calls or any other means.

    By accepting this Privacy Policy you agree that you understand and accept the use of your personal information as set out in this policy. If you do not agree with the terms of this Privacy Policy please do not use the Website or otherwise provide us with your personal information.


    <h2>Who we are</h2>

    References in this Privacy Policy to ForteBet, “we”, “us” or “our” relate GRAND VICTORIA LTD, a limited liability company (Reg. No. 3), trading as Fortebet, having its current address at Plot 3, Dundas Road, Lower Kololo Terrace, Kololo Courts Hotel, 1st Floor. We control the ways your Personal Data is collected and the purposes for which your Personal Data is used by ForteBet, acting as the “data controller” for the purposes of applicable data protection legislation.

    <h2>Contacting us</h2>

    If you have any concerns, or would like more detail about how we process your Personal Data, you can contact us using protection@fortebet.ug.


    <h2>Protecting Your Personal Data</h2>

    Your Personal Data isn’t just protected by the quality, commitment and high standards of ForteBet, it’s also protected by law. The law states that we can only process your Personal Data when there is a genuine reason to do so and it must be one of the following:
    <ul>
    <li>To fulfil any contract that we have with you</li>
    <li>We have a legal obligation</li>
    <li>Where you have consented to the processing</li>
    <li>When it is in our legitimate interest</li>
    <li>When it is in the public interest</li>
    <li>When it is in your vital interests</li>
    </ul>

    <h2>Legitimate Interests</h2>

    When we have a business or commercial reason to process your Personal Data this is referred to as a legitimate interest. Your Personal Data is still protected and we must not process it in a way that would be unfair to you or your interests.

    If we do use legitimate interests as a reason to process your Personal Data we will tell you that we are doing so, what our legitimate interests are and provide you with a method to raise any questions or objections you may have. However, compelling grounds for processing such information may over-ride your right to object.

    <h2>How long we keep your Personal Data</h2>

    Whenever your data is kept by ForteBet we will ensure that it is appropriately protected and only used for acceptable purposes.

    We will keep your data for the period that you are a customer of ForteBet.

    If you are no longer a customer of ForteBet, we will keep your data for the minimum length of time required to comply with the purposes set out in this policy and relevant legal or regulatory obligations. Your Personal Data may be kept longer if we cannot delete it for technical reasons.

    If you choose not to provide your Personal Data it may prevent us from meeting legal obligations, fulfilling a contract, or performing services required to run your account. Not providing your Personal Data may mean we are unable to provide you with products or services.

    <h2>Information Collected</h2>

    The information and data about you which we may collect, use and process includes the following:

    <ul>
    <li>Information that you provide to us by filling in forms on the Website or any other information you submit to us via the Website or email</li>
    <li>Records of correspondence, whether via the Website, email, telephone or other means</li>
    <li>Your responses to surveys or customer research that we carry out</li>
    <li>Details of the transactions you carry out with us, whether via the Website, telephone or other means</li>
    <li>Details of your visits to the Website including, but not limited to, traffic data, location data, weblogs and other communication data.</li>
    </ul>

    Where it is reasonable for us to do so and not detrimental to your rights and freedoms, we also collect Personal Data from publicly available sources such as internet searches, Companies House, and broadcast media.

    <h2>Telephone Calls</h2>

    Telephone calls to and from our Customer Contact Centre are recorded for training and security purposes along with the resolution of any queries arising from the service you receive.

    Social Features of Our Products

    If you choose to participate in any of the social features that we provide with our products ForteBet may store, record or otherwise process this data.


    <h2>Personal Data we share with others</h2>

    We may share your Personal Data within the Grand Victoria group:

    <ul>
    <li>Law enforcement agencies, regulators and other authorities</li>
    <li>Credit reference agencies</li>
    <li>Fraud prevention agencies</li>
    <li>Identity verification agencies</li>
    <li>Sports governing bodies</li>
    <li>Organisations that introduce you to us</li>
    <li>Third parties you ask us (or permit us) to share your data with</li>
    <li>Third parties necessary to provide products or services which you have requested</li>
    </ul>

    <h2>
      We will assist you if you choose to exercise any of your rights over your Personal Data, including:</h2>

    <ul>
    <li>Withdrawing your previously granted consent; however, this will not invalidate any previously consented processing</li>
    <li>Lodging a complaint with any relevant Data Protection Authority</li>
    <li>Access to your Personal Data that we hold or process</li>
    <li>Correction of any Personal Data that is incorrect or out of date</li>
    <li>Erasure of any Personal Data that we process</li>
    <li>Restrict processing of your Personal Data in certain circumstances</li>
    <li>Asking us to provide you or another company you nominate with certain aspects of your Personal Data, often referred to as ‘the right to portability’</li>
    <li>The ability to object to any processing data where we are doing it for our legitimate interests</li>
    <li>The ability to contest a decision made entirely by automated processing, to express your point of view and to request that a human review the decision</li>
    </ul>

    For more information on these rights you can contact protection@fortebet.ug

    <h2>Changes to our Privacy Statement</h2>

    We may update this policy from time to time, so please review it frequently.

    If any material changes are made to this Privacy Policy we will use reasonable endeavours to inform you in advance by email, notice on the Website or other agreed communications channels. We will communicate the changes to you in advance, giving an appropriate amount of time for you to consider and understand the changes before they become effective.

    We will not enforce material changes to the Privacy Policy without your express consent. If you decline to accept the changes to the Privacy Policy, or otherwise do not accept the changes within the time period, we may not be able to continue to provide some or all products and services.

    </div>
  );
}
